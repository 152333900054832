module.exports = function stringifyStyles(styles) {
  if ("" + styles === "[object Object]") {
    return Object.keys(styles).map(function(key) {
      return styles[key] ? key + ": " + styles[key] : null;
    }).filter(Boolean).join("; ");
  } else if (typeof styles === "string") {
    return styles;
  } else {
    return "";
  }
};
