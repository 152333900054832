/**
 * Fixes issues of the floating point like 0.0000001.
 *
 * @param {Number} float - The number with possible problem
 *
 * @returns {Number} Fixed number.
 */
export default function(float) {
  return parseFloat(float.toFixed(2));
}
