var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'wayfinder-category-legend',
    {
      'wayfinder-category-legend_is-root': _vm.isRoot,
      'wayfinder-category-legend_empty': !_vm._hasChildren(_vm.legend)
    }
  ]},[(_vm.isRoot)?_c('button',{staticClass:"wayfinder-category-legend--label",attrs:{"data-marker-id":_vm.element.id,"tabindex":"0"},on:{"click":function($event){return _vm._toggleLegendElement(_vm.element)}}},[_vm._v(" "+_vm._s(_vm.$withLocale(_vm.label))+" "),(_vm._hasChildren(_vm.legend))?_c('i',{staticClass:"wayfinder-category-legend--section-element-activity-icon fa-light fa-angle-right"}):_vm._e()]):_vm._e(),_c('wayfinder-collapsable',{attrs:{"collapsed":!_vm.isActive}},[_c('ul',{staticClass:"wayfinder-category-legend--section"},_vm._l((_vm.legend),function(legendElement){return _c('li',{key:legendElement.id,class:[
          'wayfinder-category-legend--section-element',
          { 'wayfinder-category-legend--section-element_active': legendElement._isActive }
        ],style:({ '--element-color': legendElement.color })},[_c('button',{staticClass:"wayfinder-category-legend--section-element-label",attrs:{"data-marker-id":legendElement.id,"tabindex":_vm.isActive ? 0 : -1},on:{"click":function($event){return _vm._handleChildElement(legendElement)}}},[_c('i',{staticClass:"wayfinder-category-legend--section-element-label_map-marker fa-light fa-location-dot "}),_vm._v(" "+_vm._s(_vm.$withLocale(legendElement.label))+" "),(_vm._hasChildren(legendElement.sub))?_c('i',{staticClass:"wayfinder-category-legend--section-element-activity-icon fa-light fa-angle-right"}):_vm._e()]),(_vm._hasChildren(legendElement.sub))?_c('wayfinder-category-legend',{attrs:{"isActive":legendElement._isActive,"isRoot":false,"legend":legendElement.sub}}):_vm._e()],1)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }