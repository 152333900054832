<template>
  <div
    :class="[
      'wayfinder',
      {
        'wayfinder_desktop': isDesktop && !isMobile,
        'wayfinder_mobile': isMobile,
        'wayfinder_wihtout-transition': !useTransition
      }
    ]"
  >
    <loader v-if="viewState.loading === true" />
    <div class="wayfinder--error-message" v-else-if="viewState.error">
      {{ $l10n(viewState.error) }}
    </div>
    <template v-else>
      <wayfinder-content :key="'content-' + loadedDictionary" />
      <wayfinder-sidebar :key="'sidebar-' + loadedDictionary" />
    </template>

    <focus-frame />
  </div>
</template>

<style src="./components/core/common.less" lang="less"></style>

<script>
  import { mapState, mapActions } from "vuex";
  import { loadLocalizationDictionary } from "../dashboard/lib/l10n.js";
  import { getSearch } from "./lib/utils/url-query.js";

  import Loader from "./components/loader/loader.vue";
  import WayfinderContent from "./components/core/layout-content.vue";
  import WayfinderSidebar from "./components/core/layout-sidebar.vue";
  import FocusFrame from "./components/focus-frame/focus-frame.vue";

  const urlOptions = getSearch();
  export default {
    name: "wayfinder-layout",
    watch: {
      async selectedLocale(newLocale) {
        await loadLocalizationDictionary(newLocale.toLowerCase());
        this._setLoadedDictionary(newLocale);
        this._addAttributeToHTML(newLocale);
      }
    },
    computed: {
      ...mapState({
        viewState: state => state.view,
        useTransition: state => state.useTransition,
        loadedDictionary: state => state.loadedDictionary
      }),
      selectedLocale() {
        return this.$store.getters.selectedLocale;
      }
    },
    data() {
      return {
        isDesktop: "desktop" in urlOptions,
        isMobile: this._isMobileCheck()
      };
    },
    methods: {
      ...mapActions({
        fetchView: "view/fetchView",
        _setScreenModificator: "setScreenModificator",
        _showSidebar: "showSidebar",
        _hideSidebar: "hideSidebar",
        _hideKeyboard: "hideKeyboard",
        _resetKeyboardPosition: "resetKeyboardPosition",
        _setLegendFilter: "setLegendFilter",
        _selectLocale: "selectLocale",
        _setLoadedDictionary: "setLoadedDictionary",
        _resetViewState: "view/resetViewState"
      }),
      _addAttributeToHTML(value){
        let htmlElement = document.querySelector("html");
        htmlElement.setAttribute("lang", value);
      },
      _isMobileCheck() {
        /* TODO: add the checking of user-agent. */
        return window.innerWidth < 900;
      },
      _updateIsMobile() {
        this.isMobile = this._isMobileCheck();
        this._setScreenModificator({
          isMobile: this.isMobile,
          isDesktop: this.isDesktop && !this.isMobile
        });

        if (this.isMobile) {
          this._hideSidebar();
        }
      }
    },
    created() {
      this._updateIsMobile = this._updateIsMobile.bind(this);
    },
    async mounted() {
      this._updateIsMobile();

      await this.fetchView({ viewId: urlOptions.view });
      window.addEventListener("resize", this._updateIsMobile);
    },
    beforeDestroy() {
      window.removeEventListener("resize", this._updateIsMobile);
    },
    screenPending() {
      this._showSidebar();
      this._hideKeyboard();
      this._resetKeyboardPosition();
      this._setLegendFilter("");
      this._selectLocale("");
      this._resetViewState();
    },
    components: {
      Loader,
      WayfinderContent,
      WayfinderSidebar,
      FocusFrame
    }
  };
</script>
