import stringifyStyles from "../../../../lib/stringify-styles.js";

export default (l10n) => {
  return [
    [
      { lowerCase: "$", upperCase: "§", color: "light-gray" },
      { lowerCase: "1", upperCase: "!", color: "light-gray" },
      { lowerCase: "2", upperCase: '"', color: "light-gray" },
      { lowerCase: "3", upperCase: "#", color: "light-gray" },
      { lowerCase: "4", upperCase: "€", color: "light-gray" },
      { lowerCase: "5", upperCase: "%", color: "light-gray" },
      { lowerCase: "6", upperCase: "&", color: "light-gray" },
      { lowerCase: "7", upperCase: "/", color: "light-gray" },
      { lowerCase: "8", upperCase: "(", color: "light-gray" },
      { lowerCase: "9", upperCase: ")", color: "light-gray" },
      { lowerCase: "0", upperCase: "=", color: "light-gray" },
      { lowerCase: "+", upperCase: "?", color: "light-gray" },
      { label: "`", color: "light-gray" },
      {
        special: "backspace",
        icon: {
          className: "fa fa-long-arrow-left",
          placement: "right",
          style: {
            top: "2px",
            fontSize: "44px"
          }
        },
        style: {
          "width": 101
        },
        color: "light-gray"
      }
    ],
    [{
      special: "tab",
      label: "Tab",
      color: "light-gray",
      style: {
        width: 77,
        fontSize: "14px"
      },
      disabled: true
    },
    "q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "ă", "î",
    {
      label: "â",
      style: {
        width: 69
      }
    }
    ],
    [{
      special: "capslock",
      label: "Caps <br/>Lock",
      color: "light-gray",
      style: {
        width: 92,
        fontSize: "14px",
        textAlign: "left"
      }
    },
    "a", "s", "d", "f", "g", "h", "j", "k", "l", "ș", "ț",
    {
      special: "enter",
      fn: function(position, override) {
        var label = l10n("Search");
        var style = {
          border: "none",
          transform: "scale(1.05)",
          "z-index": 1,
          top: position.top + 1,
          left: position.left + 2,
          width: 110,
          height: 43
        };

        if (override) {
          for (var key in override.style) {
            style[key] = override.style[key];
          }

          label = override.label || label;
        }

        style = stringifyStyles(style);
        /* eslint-disable */
          return `
          <svg
            class="keyboard-fullsize--key keyboard-fullsize--svg-key"
            style="${ style }"
            viewBox="0 0 28.077731 11.490319"
          >
            <g transform="translate(-76.657379,-112.40141)">
              <rect
                x="76.789879"
                y="112.53391"
                rx="0.60000002"
                height="11.225318"
                width="27.812731"
                style="opacity:1;fill:${ override && override.fill ? override.fill : "#43a09d" };fill-opacity:1;stroke:#ffffff;stroke-width:0.26499999;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
              />
              <g transform="translate(0.11380229,0.4832771)">
                <text><tspan
                  x="80.62925"
                  y="119.7528"
                  style="font-weight:bold;font-size:5.29166651px;fill:#b3b3b3;stroke:none;"
                >${ label }</tspan></text>
                <text><tspan
                  x="80.525299"
                  y="119.62522"
                  style="font-weight:bold;font-size:5.29166651px;font-family:sans-serif;fill:#ffffff;stroke:none;"
                >${ label }</tspan></text>
              </g>
            </g>
          </svg>`;
          /* eslint-enable */
      }
    }
    ],
    [{
      special: "lshift",
      label: "Shift",
      icon: {
        className: "far fa-arrow-alt-up",
        placement: "left",
        style: {
          fontSize: "24px",
          marginLeft: "4px"
        }
      },
      style: {
        fontSize: "14px",
        width: 118
      },
      color: "light-gray"
    },
    "z", "x", "c", "v", "b", "n", "m",
    { lowerCase: ",", upperCase: ";" },
    { lowerCase: ".", upperCase: ":" },
    { lowerCase: "-", upperCase: "_" },
    { label: "@", color: "light-gray" },
    {
      special: "rshift",
      label: "Shift",
      icon: {
        className: "far fa-arrow-alt-up",
        placement: "right",
        style: {
          fontSize: "24px",
          marginRight: "-4px",
          marginLeft: "4px"
        }
      },
      style: {
        fontSize: "14px",
        width: 84
      },
      color: "light-gray"
    }
    ],
    [
      { label: "/", color: "light-gray", style: { width: 61 } },
      { label: "<", color: "light-gray", style: { width: 61 } },
      { label: ">", color: "light-gray", style: { width: 61 } },
      { special: "space", label: " ", style: { width: 330 } },
      { label: "\"", color: "light-gray", style: { width: 60 } },
      { label: "*", color: "light-gray", style: { width: 60 } },
      { label: "◄", color: "light-gray", style: { width: 60 } },
      { label: "►", color: "light-gray", style: { width: 60 } }
    ]
  ];
};
