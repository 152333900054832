<template>
  <div
    :class="[
      'wayfinder-category-legend',
      {
        'wayfinder-category-legend_is-root': isRoot,
        'wayfinder-category-legend_empty': !_hasChildren(legend)
      }
    ]"
  >
    <button
      v-if="isRoot"
      :data-marker-id="element.id"
      class="wayfinder-category-legend--label"
      tabindex="0"
      @click="_toggleLegendElement(element)"
    >
      {{ $withLocale(label) }}
      <i
        v-if="_hasChildren(legend)"
        class="wayfinder-category-legend--section-element-activity-icon fa-light fa-angle-right"
      ></i>
    </button>

    <wayfinder-collapsable :collapsed="!isActive">
      <ul class="wayfinder-category-legend--section">
        <li
          v-for="legendElement in legend"
          :key="legendElement.id"
          :class="[
            'wayfinder-category-legend--section-element',
            { 'wayfinder-category-legend--section-element_active': legendElement._isActive }
          ]"
          :style="{ '--element-color': legendElement.color }"
        >
          <button
            :data-marker-id="legendElement.id"
            :tabindex=" isActive ? 0 : -1"
            class="wayfinder-category-legend--section-element-label"
            @click="_handleChildElement(legendElement)"
          >
            <i class="wayfinder-category-legend--section-element-label_map-marker fa-light fa-location-dot "></i>
            {{ $withLocale(legendElement.label) }}
            <i
              v-if="_hasChildren(legendElement.sub)"
              class="wayfinder-category-legend--section-element-activity-icon fa-light fa-angle-right"
            ></i>
          </button>

          <wayfinder-category-legend
            v-if="_hasChildren(legendElement.sub)"
            :isActive="legendElement._isActive"
            :isRoot="false"
            :legend="legendElement.sub"
          />
        </li>
      </ul>
    </wayfinder-collapsable>
  </div>
</template>

<style lang="less" src="./category-legend.less"></style>
<script>
  import WayfinderCollapsable from "../collapsable/collapsable.vue";
  import { mapActions } from "vuex";

  export default {
    name: "wayfinder-category-legend",
    props: {
      isRoot: {
        type: Boolean,
        default: true
      },
      element: Object,
      isActive: Boolean,
      label: Object,
      legend: Array
    },
    methods: {
      ...mapActions({
        _toggleLegendElement: "toggleLegendElement",
        _selectLegendElements: "selectLegendElements",
        _hideKeyboard: "hideKeyboard"
      }),
      _hasChildren(legend) {
        return legend && legend.length !== 0;
      },
      _handleChildElement(element) {
        if (this._hasChildren(element.sub)) {
          this._toggleLegendElement(element);
        } else {
          this._selectLegendElements(element);
          this._hideKeyboard();
        }
      }
    },
    components: {
      WayfinderCollapsable
    }
  };
</script>
