import stringifyStyles from "../../../../lib/stringify-styles.js";

export default (l10n) => {
  return [
    [
      { lowerCase: "$", upperCase: "§", color: "light-gray" },
      { lowerCase: "1", upperCase: "!", color: "light-gray" },
      { lowerCase: "2", upperCase: '"', color: "light-gray" },
      { lowerCase: "3", upperCase: "#", color: "light-gray" },
      { lowerCase: "4", upperCase: "€", color: "light-gray" },
      { lowerCase: "5", upperCase: "%", color: "light-gray" },
      { lowerCase: "6", upperCase: "&", color: "light-gray" },
      { lowerCase: "7", upperCase: "/", color: "light-gray" },
      { lowerCase: "8", upperCase: "(", color: "light-gray" },
      { lowerCase: "9", upperCase: ")", color: "light-gray" },
      { lowerCase: "0", upperCase: "=", color: "light-gray" },
      { lowerCase: "+", upperCase: "?", color: "light-gray" },
      { label: "`", color: "light-gray" },
      {
        special: "backspace",
        icon: {
          className: "fa fa-long-arrow-left",
          placement: "right",
          style: {
            top: "2px",
            fontSize: "44px"
          }
        },
        style: {
          "width": 101
        },
        color: "light-gray"
      }
    ],
    [{
      special: "tab",
      label: "Tab",
      color: "light-gray",
      style: {
        width: 77,
        fontSize: "14px"
      },
      disabled: true
    },
    "q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "å",
    { lowerCase: "¨", upperCase: "^", color: "light-gray" }
    ],
    [{
      special: "capslock",
      label: "Caps <br/>Lock",
      color: "light-gray",
      style: {
        width: 92,
        fontSize: "14px",
        textAlign: "left"
      }
    },
    "a", "s", "d", "f", "g", "h", "j", "k", "l", "ö", "ä",
    {
      special: "enter",
      fn: function(position, override) {
        override = override || {};

        var label = l10n("Search");
        var style = {
          border: "none",
          transform: "scale(1.05)",
          "z-index": 1,
          top: position.top - 56 + (override.offsetTop || 0),
          left: position.left + 2 + (override.offsetleft || 0),
          width: 109,
          height: 101
        };

        if (override) {
          for (var key in override.style) {
            style[key] = override.style[key];
          }

          label = override.label || label;
        }

        style = stringifyStyles(style);
        /* eslint-disable */
          const path = override.path || `M15.026,787.269C13.565,787.269 12.356,788.304 12.085,789.683L12.085,837.756C12.085,839.418 10.747,840.756 9.085,840.756L-2.68,840.756L-2.68,840.774L-24.75,840.774C-26.412,840.774 -27.75,842.112 -27.75,843.774L-27.75,879.307C-27.75,880.969 -26.412,882.307 -24.75,882.307L17.951,882.307C18.232,882.394 18.525,882.456 18.836,882.456L72.607,882.456C74.269,882.456 75.607,881.118 75.607,879.456L75.607,790.269C75.607,788.607 74.269,787.269 72.607,787.269L15.026,787.269L15.026,787.269Z`;
          return `
          <svg
            class="keyboard-fullsize--key keyboard-fullsize--svg-key"
            style="${style}"
            width="109"
            height="101"
            viewBox="0 0 109 101"
          >
            <g transform="translate(0,-951.36216)">
              <g transform="translate(29.142855,166.99998)">
                <path
                  d="${ path }"
                  style="opacity:1;fill:${ override && override.fill ? override.fill : "#43a09d" };fill-opacity:1;stroke:#ffffff;stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1"
                />
                <text><tspan
                  x="25.035723"
                  y="868.55865"
                  text-anchor="middle"
                  style="font-weight:bold;font-size:20px;fill:#b3b3b3"
                >${ label }</tspan></text>
                <text><tspan
                  x="24.642866"
                  y="868.07648"
                  text-anchor="middle"
                  style="font-weight:bold;font-size:20px;fill:#ffffff"
                >${ label }</tspan></text>
              </g>
            </g>
          </svg>`;
          /* eslint-enable */
      }
    }
    ],
    [{
      special: "lshift",
      label: "Shift",
      icon: {
        className: "far fa-arrow-alt-up",
        placement: "left",
        style: {
          fontSize: "24px",
          marginLeft: "4px"
        }
      },
      style: {
        fontSize: "14px",
        width: 118
      },
      color: "light-gray"
    },
    "z", "x", "c", "v", "b", "n", "m",
    { lowerCase: ",", upperCase: ";" },
    { lowerCase: ".", upperCase: ":" },
    { lowerCase: "-", upperCase: "_" },
    { label: "@", color: "light-gray" },
    {
      special: "rshift",
      label: "Shift",
      icon: {
        className: "far fa-arrow-alt-up",
        placement: "right",
        style: {
          fontSize: "24px",
          marginRight: "-4px",
          marginLeft: "4px"
        }
      },
      style: {
        fontSize: "14px",
        width: 84
      },
      color: "light-gray"
    }
    ],
    [
      { label: "/", color: "light-gray", style: { width: 61 } },
      { label: "<", color: "light-gray", style: { width: 61 } },
      { label: ">", color: "light-gray", style: { width: 61 } },
      { special: "space", label: " ", style: { width: 330 } },
      { label: "\"", color: "light-gray", style: { width: 60 } },
      { label: "*", color: "light-gray", style: { width: 60 } },
      { label: "◄", color: "light-gray", style: { width: 60 } },
      { label: "►", color: "light-gray", style: { width: 60 } }
    ]
  ];
};
