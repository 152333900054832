<template>
  <div class="wayfinder-service-legend">
    <div
      v-for="legendElement in legend"
      :key="legendElement.id"
      class="wayfinder-service-legend-element"
    >
      <wayfinder-button
        :id="`service-button-${ legendElement.id }`"
        :active="_isActive(legendElement)"
        :data-marker-id="legendElement.id"
        :title="$withLocale(legendElement.label)"
        class="wayfinder-service-legend-element-button"
        color="primary"
        corners="circle"
        @click.native="() => _handleElement(legendElement)"
      >
        <i :class="['wayfinder-service-legend-element-icon', legendElement.class]"></i>
      </wayfinder-button>
      <label
        :for="`service-button-${ legendElement.id }`"
        class="wayfinder-service-legend-element-label"
        @mouseup="(event) => _blurButton(event)"
      >{{ $withLocale(legendElement.label) }}</label>
    </div>
  </div>
</template>

<style lang="less" src="./service-legend.less"></style>
<script>
  import { mapActions } from "vuex";

  import WayfinderButton from "../button/button.vue";

  export default {
    name: "wayfinder-service-legend",
    watch: {
      legendElement: {
        deep: true,
        handler() {}
      }
    },
    props: {
      legend: Array
    },
    methods: {
      ...mapActions({
        _selectLegendElements: "selectLegendElements",
        _hideKeyboard: "hideKeyboard"
      }),
      /* Hack. Used for allow fire the "active" css action few times in a row by clicking on label. */
      _blurButton() {
        setTimeout(() => document.activeElement.blur(), 50);
      },
      _handleElement(legendElement) {
        this._selectLegendElements(legendElement);
        this._hideKeyboard();
      },
      _isActive(legendElement) {
        return this.$store.getters.selectedLegendElements.some(element => element.id === legendElement.id);
      }
    },
    components: {
      WayfinderButton
    }
  };
</script>
