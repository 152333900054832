/* eslint-disable */
(function(root, factory) {
  // Configuration
  var exportName = 'DK5';

  if (typeof exports === 'object') {
    // Node. Does not work with strict CommonJS, but
    // only CommonJS-like environments that support module.exports,
    // like Node.
    module.exports = factory.call(root);
  } else if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(exportName, [], factory);
  } else {
    // Browser globals (root is window)
    root[exportName] = factory.call(root);
  }

  // Dependencies passed as arguments
}(this, function() {
  /*
   * Constructor of DK5 number (https://en.wikipedia.org/wiki/Danish_Bibliographic_Centre).
   *
   * @params {string} rule - The DK5 number based on VendorCode with possible range from 0 to 99.99999, letters not allowed.
   *
   * @returns {DK5} - instance of DK5.
   */
  function DK5(rule) {
    this.rule = rule;
    this.dk5 = new VendorСode(rule, { numberRange: { from: 0, to: 99.99999 } });

    var wrongRuleIndex = this.dk5.getRules().findIndex(function(rule) {
      return rule.type === "string";
    });

    if (wrongRuleIndex !== -1 && rule) {
      this.error = {
        code: "INITIALIZATION_ERROR",
        message: "DK5 does not have string rules, use for it 'Author'.",
        index: wrongRuleIndex
      }
    }
  };

  /*
   * Proxy for VendorCode instance test function.
   *
   * @param {number} query - The number to check if it fits in dk5 range with selected rule.
   *
   * @returns {boolean} - True if the query fits to dk5 rule.
   */
  DK5.prototype.test = function(query) {
    return this.dk5.test(query);
  };

  /*
   * Get the errors of rules after instantiating.
   *
   * @returns {object[]} - List of errors in format `{ code: "ERROR_CODE", message: "", index: indexOfWrongRule }`.
   */
  DK5.prototype.getErrors = function() {
    return [this.error].filter(Boolean).concat(this.dk5.getErrors());
  };

  /*
   * Constructor of deway number (https://en.wikipedia.org/wiki/Dewey_Decimal_Classification).
   *
   * @params {string} rule - The dewey number based on VendorCode with possible range from 0 to infinity, letters not allowed.
   *
   * @returns {Dewey} - instance of Dewey.
   */
  function Dewey(rule) {
    this.rule = rule;
    this.dk5 = new VendorСode(rule, { numberRange: { from: 0, to: Infinity } });

    var wrongRuleIndex = this.dk5.getRules().findIndex(function(rule) {
      return rule.type === 'combined' || rule.type === "string";
    });

    if (wrongRuleIndex !== -1 && rule) {
      this.error = {
        code: "INITIALIZATION_ERROR",
        message: "Dewey does not have string rules, use for it 'Author'.",
        index: wrongRuleIndex
      }
    }
  };
  /*
   * Proxy for VendorCode instance test function.
   *
   * @param {number} query - The number to check if it fits in dewey range with selected rule.
   *
   * @returns {boolean} - True if the query fits to dewey rule.
   */
  Dewey.prototype.test = function(query) {
    return this.dk5.test(query);
  };

  /*
   * Get the errors of rules after instantiating.
   *
   * @returns {object[]} - List of errors in format `{ code: "ERROR_CODE", message: "", index: indexOfWrongRule }`.
   */
  Dewey.prototype.getErrors = function() {
    return [this.error].filter(Boolean).concat(this.dk5.getErrors());
  };

  /*
   * Constructor of autor rules (the alphabetical range like A-C or Ab-Af). Indicates which titles could be found one selected place\shelf.
   *
   * @params {string} rule - The author rule based on VendorCode with possible of usage only letter, numbers not allowed.
   *
   * @returns {Author} - instance of Author.
   */
  function Author(rule) {
    this.rule = rule;
    this.dk5 = new VendorСode(rule);

    var numberRuleIndex = this.dk5.getRules().findIndex(function(rule) {
      return rule.type === 'number';
    });

    if (numberRuleIndex !== -1) {
      this.error = {
        code: "INITIALIZATION_ERROR",
        message: "Author does not have number rules, use for it 'DK5'.",
        index: numberRuleIndex
      }
    }
  }

  /*
   * Proxy for VendorCode instance test function.
   *
   * @param {string} query - The string to check if it fits in author rule.
   *
   * @returns {boolean} - True if the query fits to rule.
   */
  Author.prototype.test = function(query) {
    return this.dk5.test(query);
  };

  /*
   * Get the errors of rules after instantiating.
   *
   * @returns {object[]} - List of errors in format `{ code: "ERROR_CODE", message: "", index: indexOfWrongRule }`.
   */
  Author.prototype.getErrors = function() {
    return [this.error].filter(Boolean).concat(this.dk5.getErrors());
  };

  /*
   * Constructor of shelfmark. A shelfmark is a set of letters and numbers on the spine of a book or journal
   * which indicates where the item is shelved in the Library.  For example, A History of Northern Ireland has
   * the shelfmark of DA973 HENN. Books are shelved according to their shelfmark. Each shelf is clearly labelled.
   * You will find the shelfmark by searching the Library catalogue.
   *
   * @params {string} rule - The dewey number based on VendorCode with possible range from 0 to 10000, the rage rule of shelfmark can have letters like: u100-u150.
   *
   * @returns {Dewey} - instance of Shelfmark.
   */
  function Shelfmark(rule) {
    this.rule = rule;
    this.dk5 = new VendorСode(rule, {
      numberRange: {
        from: 0,
        to: 10000
      },
      shelfmark: true
    });
  };

  /*
   * Proxy for VendorCode instance test function.
   *
   * @param {string} query - The string to check if it fits in shelfmark range with selected rule.
   *
   * @returns {boolean} - True if the query fits to dewey rule.
   */
  Shelfmark.prototype.test = function(query) {
    return this.dk5.test(query);
  };

  /*
   * Get the errors of rules after instantiating.
   *
   * @returns {object[]} - List of errors in format `{ code: "ERROR_CODE", message: "", index: indexOfWrongRule }`.
   */
  Shelfmark.prototype.getErrors = function() {
    return [this.error].filter(Boolean).concat(this.dk5.getErrors());
  };

  /*
   * Constructor of common vendor code like dk5, dewey and etc.
   * Supports numeric, alphabetic and mixed ranges, also as a whilecard for letters and numbers.
   * The alphabetic ranges support only the english (a-z) and danish letters (æ, ø, å).
   * - Ranges: 1--12.2.
   * - Wildcards: 1.*2, A*--Bc.
   * - The multiple rules supports by comma-sepparated list without spaces.
   *
   * @params {string} dk5Number - The any rule(s) of vendor code.
   * @params {object} options - options of vendor code.
   * @params {object} options.numberRange - The rande limits for number ranges.
   * @params {object} [options.numberRange.from=0] - Bottom limit of number range.
   * @params {object} [options.numberRange.to=99.99999] - Top limit of number range.
   * @params {boolean} [options.shelfmark=false] - The indicates what rule can have a mixed ranges (u100-u150).
   * @params {*} options[*] - any other params, which should be written to instance.
   *
   * @returns {VendorСode} - The instance of VendorСode.
   */
  function VendorСode(dk5Number, options) {
    if (!options) {
      options = {};
    }

    if (!options.numberRange) {
      options.numberRange = {};
    }

    if (!options.numberRange.from) {
      options.numberRange.from = 0;
    }

    if (!options.numberRange.to) {
      options.numberRange.to = 99.99999;
    }

    Object.keys(options).forEach(function(key) {
      this[key] = options[key];
    }.bind(this));

    this.dk5 = dk5Number;
    this.rules = this.getRules(options.shelfmark);
  };

  /*
   * Instantiate rules of vendor code.
   *
   * @params {boolean} [shelfmark=false] - The indicates what rule can have a mixed ranges (u100-u150).
   *
   * @params {VendorСodeRule[]} - List of rules instances.
   */
  VendorСode.prototype.getRules = function(shelfmark) {
    if (this.rules) {
      return this.rules;
    } else {
      if (typeof this.dk5 !== 'string') {
        this.error = {
          code: "INITIALIZATION_ERROR",
          message: "Dk5 number should be not empty string."
        };

        return [];
      };

      return this.dk5.split(',').map(function(rule) {
        return new VendorСodeRule(rule, {
          numberRange: this.numberRange,
          shelfmark: shelfmark,
        });
      }.bind(this));
    }
  };

  /*
   * Validate the query by vendor code rules.
   *
   * @params {(string|number)} query - The query to validation (if fit's to some rule of vendor code instance).
   *
   * @returns {boolean} - True is some rule are passed.
   */
  VendorСode.prototype.test = function(query) {
    return (this.rules || []).some(function(rule) {
      return rule.isValid && rule.test(query);
    });
  };

  /*
   * Get the errors of rules.
   *
   * @returns {object[]} - List of errors in format `{ code: "ERROR_CODE", message: "", index: indexOfWrongRule }`.
   */
  VendorСode.prototype.getErrors = function() {
    var startPosition = 0;
    var endPosition = -1;
    var ruleErrors = this.getRules().map(function(rule, index) {
      var error = null;

      startPosition = endPosition + 1;
      endPosition += rule.originalRule.length + 1;

      if (rule.error) {
        rule.error.index = index;
        rule.error.position = {
          start: startPosition,
          end: endPosition
        };

        return rule.error;
      }
    }).filter(Boolean);

    return (this.error ? [this.error] : []).concat(ruleErrors);
  };

  /*
   * Constructor of single vendor code rule (65.21*--99.123* or 123--823 or A--B or C and etc).
   *
   * @params {string} rule - The single vendor code rule.
   * @params {boolean} [options.shelfmark=false] - The indicates what rule can have a mixed ranges (u100-u150).
   * @params {*} options[*] - any other params, which should be written to instance.
   *
   * @returns {VendorСodeRule} - Instance of VendorСodeRule.
   */
  function VendorСodeRule(rule, options) {
    if (!options) {
      options = {};
    }

    rule = ("" + rule).trim();
    this.originalRule = rule;
    if (!options.shelfmark) {
      if (/[0-9]+(?:\.[0-9]+\*)?(\-\-[0-9]+(?:\.[0-9]+\*))? .+/ig.test(rule)) { // match 65.21*--99.123*, 65.21*--99, 65--99, 65--99.21
        this.type = 'combined';
      } else if (/^(\-|\d+)/ig.test(rule)) {
        this.type = 'number';
      } else {
        this.type = 'string'; //since Jakob Henriksen sayd about book title: "There could also be other characters like ,.-!“#€%&/()=?*@:<>+$"
      }
    } else {
      // if shelfmark has range u123--u823 which we use as number range 123--823
      if (/^[a-z]*\d+\-\-[a-z]*\d+/ig.test(rule)) {

        this.shelfmarkPrefix = this.getShelfmarkPrefix(rule); // Jakob: prefix should alwas be equal u123--u823, b123--b823

        rule = rule.split('--').map((_rule) => {
          return _rule.replace(this.shelfmarkPrefix, "");
        });

        // avoid the wrong rule u1200--u120
        if (parseFloat(rule[0]) > parseFloat(rule[1])) {
          this.isValid = false;
          this.error = {
            code: "VALIDATION_ERROR",
            message: "Shelfmark 1st part can\'t be bigger then 2nd"
          };
          return false;
        } else {
          rule = rule.join('--');
          this.type = 'number';
        }
      } else if (/^[a-z]*\d+/.test(rule.split(" ")[0].trim())) {
        this.type = 'combined';
      } else {
        this.type = 'string';
      }
    }

    this.rule = ("" + rule).trim();

    this.isValid = true;
    this.isRange = false;
    this.hasStar = false;

    Object.keys(options).forEach(function(key) {
      this[key] = options[key];
    }.bind(this));

    this.test = this[this.type + 'Rule'](this.rule);
  };

  VendorСodeRule.prototype.getShelfmarkPrefix = function(rule) {
    rule = rule.split("--")[0];

    if (/^[a-z]+\d+/.test(rule)) {
      const chunks = rule.split(/([a-z]+)/).filter(Boolean);
      if (chunks.length > 1) {
        return chunks[0];
      }
    }

    return "";
  }

  /*
   * Constructor-like function for test combined rule.
   *
   * @params {string} rule - The single vendor code rule.
   *
   * @returns {boolean} - True if test for rule is passed.
   */
  VendorСodeRule.prototype.combinedRule = function(rule) {
    var that = this;
    var ruleChanks = rule.split(' ');

    var numberRule = new VendorСode(ruleChanks.shift(), {
      numberRange: this.numberRange
    });

    var hasErrorInNumberPart = numberRule.rules.some(function(rule) {
      if (rule.error) {
        that.error = rule.error;
        rule.error.message += ' (number part)';

        that.isValid = false;

        return true;
      }
    });

    var authorRule = new Author(ruleChanks.join(' '));
    authorRule.dk5.rules.some(function(rule) {
      if (rule.error) {
        that.error = rule.error;
        rule.error.message += ' (author part)';

        that.isValid = false;

        return true;
      }
    });

    if (this.isValid) {
      return function(query) {
        if (/[0-9]+(?:\.[0-9]+\*)?(\-\-[0-9]+(?:\.[0-9]+\*))? .+/ig.test(query)) {
          var queryChanks = query.split(' ');
          var numberPart = queryChanks.shift();
          var authorPart = queryChanks.join(" ");

          return numberRule.test(numberPart) && authorRule.test(authorPart);
        } else {
          return false;
        }
      }
    }

    return function() { return false };
  };

  /*
   * Constructor-like function for test numeric rule.
   *
   * @params {string} rule - The single vendor code rule.
   *
   * @returns {boolean} - True if test for rule is passed.
   */
  VendorСodeRule.prototype.numberRule = function(rule) {
    this.basicValidation(rule) && this.basicNumberValidation(rule);

    if (this.isValid) {
      if (this._isRange(rule)) {
        var chanks = rule.split('--').map(function(chank, chankIndex) {
          this.numberValidation(chank);

          var hasStar = this._hasStar(chank);
          var offset = 0;
          if (hasStar) {
            offset = parseFloat('0.' + (chank.split('.')[1] || "").split('').map(function(part, index, arr) {
              if (index === arr.length - (chankIndex + 1)) {
                return "1";
              } else {
                return "0"
              }
            }).filter(Boolean).join(''));

            if (!offset && (chank.split('.')[1] || "").length === 1) {
              offset = 0.1;
            }
          }

          return {
            rule: chank,
            hasStar: hasStar,
            number: parseFloat(chank),
            offset: parseFloat(offset)
          };
        }.bind(this));

        if (this.isValid) {
          if (chanks[0].hasStar) {
            chanks[0].number -= chanks[0].offset;
          }

          if (chanks[1].hasStar) {
            chanks[1].number += chanks[1].offset;
          }

          chanks.forEach(function(chank) {
            chank.number = chank.number;
          });

          return function(query) {
            if (this.shelfmarkPrefix !== undefined) {
              var queryPrefix = this.getShelfmarkPrefix(query);
              if (this.shelfmarkPrefix === queryPrefix) {
                query = query.replace(queryPrefix, ""); // remove 'u' from u120
              } else {
                return false;
              }
            }

            query = parseFloat(query);

            var fromRule = false;
            var toRule = false;

            if (chanks[0].hasStar) {
              fromRule = query > chanks[0].number;
            } else {
              fromRule = query >= chanks[0].number;
            }

            if (fromRule) {
              if (chanks[1].hasStar) {
                toRule = query < chanks[1].number;
              } else {
                toRule = query <= chanks[1].number;
              }
            }

            return fromRule && toRule;
          }
        }
      } else {
        if (this.numberValidation(rule)) {
          if (this._hasStar(rule)) {
            var re = new RegExp("^" + rule.replace('*', '[0-9]*') + "$");
            return function(query) {
              return re.test(query);
            }
          } else {
            var number = parseFloat(rule);
            return function(query) {
              return number === parseFloat(query);
            }
          }
        }
      }
    }
    return function() { return false };
  };

  /*
   * Basic validation of the numeric rule (available symbols, range delimiter and range fit).
   *
   * @params {string} rule - The single vendor code rule.
   *
   * @returns {boolean} - True if the rule is valid, with false the error will be written to VendorСodeRule instance.
   */
  VendorСodeRule.prototype.basicNumberValidation = function(rule) {
    if (rule) {
      if (/[^0-9\-\.\*]/.test(rule)) {
        this.isValid = false;
        this.error = {
          code: "VALIDATION_ERROR",
          message: "Rule should have only numbers, \".\", \"--\" and \"*\"."
        };
        return false;
      }

      if (/[0-9\*]+\-[0-9]+/.test(rule)) {
        this.isValid = false;
        this.error = {
          code: "VALIDATION_ERROR",
          message: "Invalid range delimeter."
        };
        return false;
      }

      if (rule[0] === '-') {
        this.isValid = false;
        this.error = {
          code: "VALIDATION_ERROR",
          message: "Numbers should be in range " + this.numberRange.from + "-" + this.numberRange.to + "."
        };
        return false;
      }

      return true;
    }

    return false;
  };

  /*
   * Additional validation of the numeric rule (usage of wildcard, the fit in range with wiledcard).
   *
   * @params {string} rule - The single vendor code rule.
   *
   * @returns {boolean} - True if the rule is valid, with false the error will be written to VendorСodeRule instance.
   */
  VendorСodeRule.prototype.numberValidation = function(rule) {
    if (rule) {
      if (/((\*+\d){2,}|\*\*)/.test(rule)) {
        this.isValid = false;
        this.error = {
          code: "VALIDATION_ERROR",
          message: "Rule should have only one \"*\" in number."
        };
        return false;
      }

      if (/\d*\*\d*\./.test(rule) || rule.indexOf('.') === -1 && rule.indexOf('*') !== -1) {
        this.isValid = false;
        this.error = {
          code: "VALIDATION_ERROR",
          message: "The \"*\" should be located only in the end of decimal part of the number."
        };
        return false;
      }

      if (/\.\d*\*\d+/.test(rule)) {
        this.isValid = false;
        this.error = {
          code: "VALIDATION_ERROR",
          message: "The \"*\" should be located only in the end of decimal part of the number."
        };
        return false;
      }

      var ruleNumber = parseFloat(rule.replace('*', ''));

      if (ruleNumber < this.numberRange.from || ruleNumber > this.numberRange.to) {
        this.isValid = false;
        this.error = {
          code: "VALIDATION_ERROR",
          message: "Numbers should be in range " + this.numberRange.from + "-" + this.numberRange.to + "."
        };
        return false;
      }

      return true;
    }

    return false;
  };

  /*
   * Constructor-like function for test string rule.
   *
   * @params {string} rule - The single vendor code rule.
   *
   * @returns {boolean} - True if test for rule is passed.
   */
  VendorСodeRule.prototype.stringRule = function(rule) {
    rule = this.replaceCompositeSymbols(rule);
    this.basicValidation(rule);
    if (this.isValid) {
      if (this._isRange(rule)) {
        if (this.rangeStringValidation(rule)) {
          var chanks = rule.split('--').map(function(chank) {
            this.stringValidation(chank);

            return chank.replace('*', '');
          }.bind(this));

          if (this.isValid) {
            return function(query) {
              var fromRule = false;
              var toRule = false;

              fromRule = this.compareStrings({ doneCondition: 1, exitCondition: -1 }, chanks[0], query);
              if (fromRule) {
                toRule = this.compareStrings({ doneCondition: -1, exitCondition: 1 }, chanks[1], query);
              }

              return fromRule && toRule;
            }.bind(this);
          }
        }
      } else {
        if (this.stringValidation(rule)) {
          if (this._hasStar(rule)) {
            var re = new RegExp("^" + this.escapeRegExp(rule.replace('*', '')) + "[^]*$", 'i');
            return function(query) {
              query = ("" + query).trim();
              return re.test(query);
            }
          } else {
            rule = ("" + rule).toLowerCase();
            return function(query) {
              query = ("" + query).trim().toLowerCase();
              return rule.indexOf(query) === 0;
            }
          }
        }
      }
    }

    return function() { return false };
  };

  /*
   * Additional validation of the string rule (the fit in range).
   *
   * @params {string} rule - The single vendor code rule.
   *
   * @returns {boolean} - True if the rule is valid, with false the error will be written to VendorСodeRule instance.
   */
  VendorСodeRule.prototype.rangeStringValidation = function(rule) {
    if (/[^a-zæøå\-\* ]/ig.test(rule)) {
      this.isValid = false;
      this.error = {
        code: "VALIDATION_ERROR",
        message: "String ranges support only alphabet letters."
      };
      return false;
    }

    return true;
  };

  /*
   * Basic validation of the string rule (the correct usage of wildcard).
   *
   * @params {string} rule - The single vendor code rule.
   *
   * @returns {boolean} - True if the rule is valid, with false the error will be written to VendorСodeRule instance.
   */
  VendorСodeRule.prototype.stringValidation = function(rule) {
    if (/(([^*]+\*|\*[^*]+){2,}|\*\*)/.test(rule)) {
      this.isValid = false;
      this.error = {
        code: "VALIDATION_ERROR",
        message: "Rule should have only one \"*\" in number."
      };
      return false;
    }

    if (!/^[^\*]*\*?$/.test(rule)) {
      this.isValid = false;
      this.error = {
        code: "VALIDATION_ERROR",
        message: "The \"*\" should be located at the end of string."
      };
      return false;
    }

    return true;
  };

  /*
   * Replacing of danish composite symbols (á, â, ë, é, ê and etc) for english equivalents (a, e and etc).
   *
   * @params {string} rule - The single vendor code rule.
   *
   * @params {string} - The rule with replaced composite symbols.
   */
  VendorСodeRule.prototype.replaceCompositeSymbols = function(rule) {
    rule = rule.replace(/[áâ]/ig, 'a');
    rule = rule.replace(/[ëéê]/ig, 'e');
    rule = rule.replace(/[óô]/ig, 'o');
    rule = rule.replace(/[ÿýŷ]/ig, 'y');
    rule = rule.replace(/[üúû]/ig, 'u');
    rule = rule.replace(/[ïíî]/ig, 'i');
    rule = rule.replace(/ä/ig, 'å');
    rule = rule.replace(/ö/ig, 'ø');

    return rule;
  };

  /*
   * Basic validation of any type of rule (empty, amount of ranges).
   *
   * @params {string} rule - The single vendor code rule.
   *
   * @returns {boolean} - True if the rule is valid, with false the error will be written to VendorСodeRule instance.
   */
  VendorСodeRule.prototype.basicValidation = function(rule) {
    if (rule) {
      if (/(\-\-.*){2,}/.test(rule)) {
        this.isValid = false;
        this.error = {
          code: "VALIDATION_ERROR",
          message: "Rule should have only one range."
        };
        return false;
      }

      return true;
    }

    this.isValid = false;
    this.error = {
      code: "VALIDATION_ERROR",
      message: "Rule can't be empty."
    };

    return false;
  };

  /*
   * Checks if rule has a star (wildcard).
   *
   * @params {string} rule - The single vendor code rule.
   *
   * @returns {boolean} - True if has.
   */
  VendorСodeRule.prototype._hasStar = function(rule) {
    this.hasStar = !!rule && ("" + rule).indexOf('*') !== -1;
    return this.hasStar;
  };

  /*
   * Checks if rule is range (using --).
   *
   * @params {string} rule - The single vendor code rule.
   *
   * @returns {boolean} - True if it's range.
   */
  VendorСodeRule.prototype._isRange = function(rule) {
    this.isRange = !!rule && ("" + rule).indexOf('--') !== -1;
    return this.isRange;
  };

  /*
   * Escape the special chars in string to use with RexExp constructor (tabs, line ends and other spacings will be replaced to space char).
   *
   * @params {string} text - The any string to escaping.
   *
   * @returns {text} - string with excaped chars.
   */
  VendorСodeRule.prototype.escapeRegExp = function(text) {
    text = "" + text;
    text = text.trim();
    text = text.replace(/[\t\n\r\b\f\v ]+/ig, ' ');
    text = text.replace(/[^a-z0-9 ]/ig, function(ch) {
      if (ch) {
        return '\\' + ch;
      } else {
        return "";
      }
    });

    return text;
  };

  /*
   * Compare to letters, function can be used in `[].sort()` method wihtout wrappers.
   * if letterA > letterB then return 1
   * if letterA < letterB then return -1
   * if letterA == letterB then return 0
   *
   * @params {string} letterA - The symbol A for comparison.
   * @params {string} letterB - The symbol B for comparison.
   *
   * @returns {number} - See the possible values and condition in description of this function.
   */
  VendorСodeRule.prototype.compareLetters = function(letterA, letterB) {
    letterA = ("" + letterA).toLowerCase();
    letterB = ("" + letterB).toLowerCase();

    if (letterA === letterB) {
      return 0;
    }

    var englishLetterRE = /[a-z]/;
    var isEnglishLetterA = englishLetterRE.test(letterA);
    var isEnglishLetterB = englishLetterRE.test(letterB);

    if (isEnglishLetterA && isEnglishLetterB) {
      return letterA < letterB ? -1 : 1;
    } else if (isEnglishLetterA && !isEnglishLetterB) {
      return -1;
    } else if (!isEnglishLetterA && isEnglishLetterB) {
      return 1;
    } else {
      var danishComparisonTable = {
        "æ": 1,
        "ø": 2,
        "å": 3
      };

      return danishComparisonTable[letterA] < danishComparisonTable[letterB] ? -1 : 1;
    }
  };

  /*
   * Compare to string letter by letter with wildcard.
   * if all leters from stringA equal letters from stringB then true
   * if comparison result of strings letter equal doneCondition then true
   * if comparison result of strings letter equal exitCondition then false
   *
   * @params {object} options - The comparison options.
   * @params {number} options.exitCondition - The condition of letters comparison to exit from string copmarison.
   * @params {number} options.doneCondition - The condition of letters comparison to finish string copmarison.
   * @params {string} stringA - The string A for comparison.
   * @params {string} stringB - The string B for comparison.
   *
   * @returns {boolean} - See the possible values and condition in description of this function.
   */
  VendorСodeRule.prototype.compareStrings = function(options, stringA, stringB) {
    var optionsIsObject = !!options && typeof options === 'object';
    var existExitCondition = options.exitCondition || options.exitCondition === 0;
    var existDoneCondition = options.doneCondition || options.doneCondition === 0;

    if (!optionsIsObject || !existExitCondition || !existDoneCondition) {
      console.error('String comparison cannot be finished without exit and done conditions.');
      return false;
    }

    var result = false;
    ("" + stringA).split('').some(function(stringALetter, index, stringALetters) {
      var stringBLetter = stringB[index];
      if (stringBLetter === undefined) {
        return true;
      }
      var comparisonResult = this.compareLetters(stringBLetter, stringALetter);

      if (comparisonResult === options.exitCondition) {
        return true;
      } else if (comparisonResult === options.doneCondition || (comparisonResult === 0 && stringALetters.length - 1 === index)) {
        result = true;
        return true;
      }
    }.bind(this));

    return result;
  };

  DK5.Author = Author;
  DK5.Dewey = Dewey;
  DK5.Shelfmark = Shelfmark;

  return DK5;
}));
/* eslint-enable */
