<template>
  <div :class="['canvas-control', orientation]">
    <i
      v-if="canvasRotate"
      class="fa-light fa-retweet canvas-control--rotate-button"
      @click="_rotateCanvas"
    ></i>
    <span
      class="fa-light canvas-control--reset-button"
      @click="_resetCanvas"
    >
      Reset
    </span>
  </div>
</template>

<style src="./canvas-control.less" lang="less"></style>

<script>
  import lodash from "lodash";
  import orientationMixin from "../mixins/orientation.js";

  export default {
    name: "canvas-control",
    mixins: [orientationMixin],
    props: {
      canvasRotate: Boolean,
      canvasMove: Boolean,
      canvasScale: Boolean,
      canvasScaleStep: {
        type: Number,
        default: 0.05
      }
    },
    methods: {
      _rotateCanvas() {
        this.$easyscreenCanvasOrientation.rotateScaleTarget();
      },
      _initCanvasMove() {
        /* Hammerjs cannot be used since the handling are binded to the middle mouse button. */
        let startX, startY, startCanvasPostionOffset;
        const onstart = (event) => {
          startX = event.pageX;
          startY = event.pageY;
          startCanvasPostionOffset = { ...this.$easyscreenCanvasOrientation.positionOffset };
        };

        const onmove = lodash.throttle((event) => {
          const deltaX = event.pageX - startX;
          const deltaY = event.pageY - startY;

          this.$easyscreenCanvasOrientation.positionOffset = {
            top: startCanvasPostionOffset.top + deltaY,
            left: startCanvasPostionOffset.left + deltaX
          };
          this.$easyscreenCanvasOrientation.updateScale();
        }, 16); // Timeout for events due to next animation frame (the number of calls is usually 60 

        this._mouseDownHanlder = (event) => {
          /* Handle only the mibble button click. */
          if (event.which === 2 || event.button === 4) {
            onstart(event);

            document.addEventListener("mousemove", onmove);
          }
        };
        this._mounseUpHanler = (event) => {
          if (event.which === 2 || event.button === 4) {
            document.removeEventListener("mousemove", onmove);
          }
        };

        document.addEventListener("mousedown", this._mouseDownHanlder);
        document.addEventListener("mouseup", this._mounseUpHanler);
      },
      _destroyCanvasMove() {
        this._mounseUpHanler();
        document.removeEventListener("mousedown", this._mouseDownHanlder);
        document.removeEventListener("mouseup", this._mounseUpHanler);
      },
      _initCanvasScale() {
        lodash.throttle(this._mouseWheelHandler = (event) => {
          /* The `Math.sign(event.deltaY)` will return -1 when mouse are scrolled up and 1 when down. */
          this.$easyscreenCanvasOrientation.scaleOffset += -Math.sign(event.deltaY) * this.canvasScaleStep;
          this.$easyscreenCanvasOrientation.updateScale();
        }, 16); // Timeout for events due to next animation frame (the number of calls is usually 60

        document.addEventListener("mousewheel", this._mouseWheelHandler);
      },
      _destroyCanvasScale() {
        document.removeEventListener("mousewheel", this._mouseWheelHandler);
      },
      _resetCanvas() {
        this.$easyscreenCanvasOrientation.positionOffset = {
          top: 0,
          left: 0
        };
        this.$easyscreenCanvasOrientation.scaleOffset = 0;
        this.$easyscreenCanvasOrientation.updateScale();
      }
    },
    mounted() {
      if (this.canvasMove) {
        this._initCanvasMove();
      }

      if (this.canvasScale) {
        this._initCanvasScale();
      }
    },
    beforeDestroy() { 
      this._destroyCanvasMove();
      this._destroyCanvasScale();
    }
  };
</script>
