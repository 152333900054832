/* lodash - utils for manipulate object properties and helpers. */
const lodash = require("lodash");

/**
 * Creates the random id based on current time in milliseconds (Date.now()) and random int in range 0-10000 (Math.random()).
 *
 * @returns {string} Random id.
 */
module.exports = function randomId() {
  return Date.now() + "_" + lodash.random(0, 10000);
};
