var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['keyboard-fullsize', ("keyboard-fullsize_" + (_vm._caseModifer()))]},_vm._l((_vm._applyOverrides(_vm.dataLayout)),function(keyboardRow,rowIndex){return _c('div',{key:("row-" + rowIndex),staticClass:"keyboard-fullsize--row"},[_vm._l((keyboardRow),function(keyOptions){
var _obj;
return [(keyOptions.fn)?_c('span',{key:("key-" + (_vm._keyIndex(keyOptions))),domProps:{"innerHTML":_vm._s(keyOptions.fn(
          _vm._getKeyPosition(rowIndex, keyOptions, keyboardRow),
          _vm.override[keyOptions.special] || {}
        ))},on:{"click":function (event) { _vm._keyClick(keyOptions, event); }}}):_c('wayfinder-button',{key:("key-" + (_vm._keyIndex(keyOptions))),class:['keyboard-fullsize--key', ( _obj = {
          'keyboard-fullsize--simple-key': typeof keyOptions === 'string',
          'keyboard-fullsize--disabled-key': keyOptions.disabled === true
        }, _obj[("keyboard-fullsize--special_" + (keyOptions.special))] = !!keyOptions.special, _obj )],style:(_vm._addUnits(Object.assign({}, _vm._getKeyPosition(rowIndex, keyOptions, keyboardRow),
          keyOptions.style))),attrs:{"active":(_vm._isShift(keyOptions) && _vm.enabledShift) || (_vm._isCapslock(keyOptions) && _vm.enabledCapslock),"aria-label-value":keyOptions.special,"color":keyOptions.color || 'gray',"tabindex-value":Number(_vm.keyboardOpened)-1,"title":keyOptions.special},nativeOn:{"click":function($event){return (function (event) { _vm._keyClick(keyOptions, event); })($event)}}},[_c('span',{staticClass:"keyboard-fullsize--key-wrapper"},[(keyOptions.icon && (keyOptions.icon.placement === 'left' || !keyOptions.icon.placement))?_c('i',{class:['keyboard-fullsize--key-icon', 'keyboard-fullsize--key-icon_left', keyOptions.icon.className],style:(_vm._addUnits(keyOptions.icon.style))}):_vm._e(),(keyOptions.upperCase)?_c('span',{staticClass:"keyboard-fullsize--upper-key"},[_vm._v(" "+_vm._s(keyOptions.upperCase)+" ")]):_vm._e(),(keyOptions.lowerCase)?_c('span',{staticClass:"keyboard-fullsize--lower-key"},[_vm._v(" "+_vm._s(keyOptions.lowerCase)+" ")]):_vm._e(),(!keyOptions.lowerCase && !keyOptions.upperCase)?_c('span',{staticClass:"keyboard-fullsize--key-label",domProps:{"innerHTML":_vm._s(_vm._keyLabel(keyOptions))}}):_vm._e(),(keyOptions.icon && (keyOptions.icon.placement === 'right'))?_c('i',{class:['keyboard-fullsize--key-icon', 'keyboard-fullsize--key-icon_right', keyOptions.icon.className],style:(_vm._addUnits(keyOptions.icon.style))}):_vm._e()])])]})],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }