import * as querystring from "querystring";

export function getSearch() {
  return querystring.parse(window.location.href.split("?")[1].split("#")[0]);
}

export function getHash() {
  return querystring.parse(window.location.href.split("#")[1]);
}

export function updateHash(patch) {
  setHash({
    ...getHash(),
    ...patch
  });
}

export function setHash(hash) {
  window.location.hash = "#" + querystring.stringify(hash);
}
