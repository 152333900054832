<template>
  <div :class="[
    'wayfinder-layout--sidebar',
    `wayfinder-layout--sidebar_keyboard-${ keyboardPosition }`,
    {
      'wayfinder-layout--sidebar_opened': sidebarOpened,
      'wayfinder-layout--sidebar_keyboard-shown': keyboardOpened
    },
    orientation
  ]">
    <div class="wayfinder-layout--sidebar-wrapper">
      <div class="wayfinder-layout--sidebar-header">
        <h1 class="wayfinder-layout--sidebar-title">
          {{ title }}
        </h1>

        <div class="wayfinder-layout--sidebar-language">
          <language-select v-model="selectedLanguage" :options="locales"/>
        </div>

        <div class="wayfinder-layout--sidebar-sub-title">
          <span class="wayfinder-layout--here-marker">
            <span
              v-if="hereMarkerId"
              @click="_handleHereElement"
            >
              {{ $l10n("You are here") }}
              <i class="wayfinder-layout--here-icon fa-light fa-crosshairs"></i>
            </span>
          </span>

          <span
            v-if="information"
            class="wayfinder-layout--information"
            @click="() => $refs.informationModal.show()"
          >
            {{ $l10n("Screen info") }}
          </span>
        </div>
      </div>

      <div class="wayfinder-layout--sidebar-content">
        <wayfinder-input
          ref="searchInput"
          v-model="legendFilter"
          :placeholder="$l10n('Fantasy, Novels, Weather')"
          :tabindex-value="inputTabindex"
          class="wayfinder--search-input"
          force-focus="wayfinder--search-keyboard"
          @blur="_hideKeyboard"
          @focus="_showKeyboard"
        />
        <scrollable ref="scrollable" class="wayfinder-layout--sidebar-scrollable" maxHeight="100%">
          <wayfinder-legend/>
        </scrollable>
      </div>


      <div v-if="!isDesktop" class="wayfinder-layout--sidebar-keyboard">
        <wayfinder-button
          :aria-label-value="$l10n('toggle keyboard position')"
          :tabindex-value="Number(keyboardOpened)-1"
          :title="$l10n('toggle keyboard position')"
          class="wayfinder-layout--keyboard-handle"
          color="black"
          corners="circle"
          data-focus-frame-follow="500"
          @click.native="_toggleKeyboardPosition"
        >
          <i class="fa-light fa-angle-down"></i>
        </wayfinder-button>
        <wayfinder-fullsize-keyboard
          :gap="9"
          :layout="keyboardMap[selectedLanguage]"
          class="wayfinder--search-keyboard"
          @key-click="_handleKeyboard"
        />
      </div>
    </div>
    <wayfinder-button
      :aria-label-value="$l10n(`${ sidebarOpened ? 'menu close' : 'menu open' }`)"
      :title="$l10n(`${ sidebarOpened ? 'menu close' : 'menu open' }`)"
      class="wayfinder-layout--sidebar-handle"
      color="primary"
      corners="circle"
      data-focus-frame-follow="500"
      @click.native="_handleSidebarAndKeyboard"
    >
      <i
        :class="[
          'fa-light',
          {
            'fa-times': sidebarOpened,
            'fa-bars': !sidebarOpened
          }
        ]"
      ></i>
    </wayfinder-button>

    <blank-modal
      v-if="information"
      ref="informationModal"
      :style="{ '--title-height': informationTitleHeight }"
      class="wayfinder-modal"
      @opened="() => _updateInformationTitleHeight()"
    >
      <div class="wayfinder-modal--content">
        <h1 ref="informationTitleRef" class="wayfinder-modal--title">{{ informationTitle }}</h1>
        <scrollable class="wayfinder-modal--text" maxHeight="100%" smoothEdgeColor="#cccccc">
          <div class="ql-snow">
            <div class="ql-editor" v-html="information"></div>
          </div>
        </scrollable>

        <wayfinder-button
          class="wayfinder-modal--close-button wayfinder-modal--close-button_top-right"
          color="white"
          corners="circle"
          @click.native="() => $refs.informationModal.hide()"
        >
          <i class="fa-light fa-times"></i>
        </wayfinder-button>

        <wayfinder-button
          class="wayfinder-modal--close-button wayfinder-modal--close-button_bottom-right"
          color="white"
          corners="circle"
          @click.native="() => $refs.informationModal.hide()"
        >
          <i class="fa-light fa-times"></i>
        </wayfinder-button>
      </div>
    </blank-modal>
  </div>
</template>

<style lang="less" src="./layout.less"></style>
<script>
  import "quill/dist/quill.core.css";
  import "quill/dist/quill.snow.css";
  import lodash from "lodash";
  import { mapActions, mapState } from "vuex";
  import orientationMixin from "../mixins/orientation.js";
  import { getSearch } from "../../lib/utils/url-query.js";
  import htmlElementHeight from "../../lib/utils/html-element-height.js";
  import BlankModal from "../modal/blank.vue";
  import Scrollable from "../scrollable/scrollable.vue";
  import LanguageSelect from "../language-select/language-select.vue";
  import WayfinderButton from "../button/button.vue";
  import WayfinderInput from "../input/input.vue";
  import WayfinderFullsizeKeyboard from "../keyboard/fullsize.vue";
  import WayfinderLegend from "../legend/legend.vue";

  const urlOptions = getSearch();
  export default {
    name: "wayfinder-layout--sidebar",
    mixins: [orientationMixin],
    watch: {
      selectedLanguage() {
        this._updateInformationTitleHeight();
      }
    },
    computed: {
      ...mapState({
        sidebarOpened: state => state.sidebarOpened,
        keyboardOpened: state => state.keyboardOpened,
        keyboardPosition: state => state.keyboardPosition,
        locales: state => state.view.locales,
        keyboardMap: state => state.view.keyboardMap
      }),
      hereMarkerId() {
        return this.$store.getters.hereMarkerId;
      },
      view() {
        return this.$store.getters.filteredView;
      },
      title() {
        return (this.view.branch && this.view.branch.name) || this.$withLocale(this.view.title);
      },
      informationTitle() {
        return lodash.get(this.view, `informationTitle[${ this.selectedLanguage }]`);
      },
      information() {
        return lodash.get(this.view, `information[${ this.selectedLanguage }]`);
      },
      selectedLanguage: {
        get() {
          return this.$store.getters.selectedLocale;
        },
        set(value) {
          this.$store.dispatch("selectLocale", value);
        }
      },
      legendFilter: {
        get() {
          return this.$store.state.legendFilter;
        },
        set(value) {
          this.$store.dispatch("setLegendFilter", value);
        }
      }
    },
    data() {
      return {
        isDesktop: "desktop" in urlOptions,
        informationTitleHeight: 0,
        inputTabindex: 0
      };
    },
    methods: {
      ...mapActions({
        _toggleSidebar: "toggleSidebar",
        _showKeyboard: "showKeyboard",
        _hideKeyboard: "hideKeyboard",
        _selectLegendElements: "selectLegendElements",
        _toggleKeyboardPosition: "toggleKeyboardPosition"
      }),
      /**
       * Handler of keyboard key-click event.
       *
       * @param {String} key - The pressed key.
       */

      setTabIndex(event) {
        if (event.keyCode === 9) {
          if (this.inputTabindex === 0) {
            this.inputTabindex = 1;
          }
        }
      },
      tabKeyObserver() {
        this.destroyTabKeyObserver();
        window.addEventListener("keydown", (event) => this.setTabIndex(event));
      },
      destroyTabKeyObserver() {
        window.removeEventListener("keydown", (event) => this.setTabIndex(event));
      },
      _handleKeyboard(key) {
        if (key === "enter") {
          return this._hideKeyboard();
        }

        this.$refs.searchInput.focus();
        WayfinderFullsizeKeyboard.defaultKeyboardHandler({
          onUpdated: this.$nextTick,
          value: {
            set: (value) => {
              this.legendFilter = value;
            },
            get: () => this.legendFilter
          },
          cursor: {
            set: (cursorIndex) => this.$refs.searchInput.setCursor(cursorIndex),
            get: () => this.$refs.searchInput.getCursor()
          }
        }, key);
      },
      _scrollToSelectedLegendElement() {
        this.$store.state.view.selectedMarkers.some(selectedMarker => {
          const legendElement = document.querySelector(`[data-marker-id='${ selectedMarker }']`);
          if (legendElement && this.$refs.scrollable) {
            this.$refs.scrollable.scrollIntoView(legendElement, {
              behavior: "instant",
              aligment: "center"
            });
          }

          return legendElement;
        });
      },
      _handleSidebarAndKeyboard() {
        if (this.keyboardOpened)
          return this._hideKeyboard();

        this._toggleSidebar();
      },
      _updateInformationTitleHeight() {
        if (!this.$refs.informationTitleRef)
          return;

        this.informationTitleHeight = `${ htmlElementHeight(this.$refs.informationTitleRef) }px`;
      },
      _handleHereElement() {
        this._selectLegendElements({ id: this.hereMarkerId });
        this._hideKeyboard();
      }
    },
    async mounted() {
      await this.$nextTick();
      this._scrollToSelectedLegendElement();
      this._updateInformationTitleHeight();
      this.tabKeyObserver();
    },
    beforeDestroy() {
      this.destroyTabKeyObserver();
    },
    async screenPending() {
      await this.$nextTick();
      this._scrollToSelectedLegendElement();

      if (this.$refs.searchInput)
        this.$refs.searchInput.blur();
    },
    components: {
      BlankModal,
      Scrollable,
      LanguageSelect,
      WayfinderButton,
      WayfinderInput,
      WayfinderFullsizeKeyboard,
      WayfinderLegend
    }
  };
</script>
