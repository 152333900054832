import lodash from "lodash";
import parseDuration from "parse-duration";
import EventEmitter from "eventemitter3";
import * as d3 from "d3-timer";
import { getHash } from "../lib/utils/url-query.js";

const hashQuery = getHash();
const minPendingTime = parseDuration(hashQuery.standBy || "10s");
/**
 * Tracks the users interactions with the screen
 *
 * @class ScreenActivity
 * @augments EventEmitter3
 *
 * @fires ScreenActivity#running
 * @fires ScreenActivity#pending
 *
 * @param {Object} options
 * @param {String} [options.pendingTime="120s"] - The minimal range since last user action to count the screen "running", can't be less than 10s.
 */
export default class ScreenActivity extends EventEmitter {
  constructor(options) {
    super();

    this.updateSettings(options);
    this.status = "running";
    this._timeout = null;

    /* Default user actions for pending. */
    this._onAction = this._onAction.bind(this);
    window.addEventListener("mousedown", this._onAction);
    window.addEventListener("touchstart", this._onAction);
    window.addEventListener("keydown", this._onAction);
  }

  /**
   * Override for `EventEmitter3.on` method (listener for a given event)
   *
   * @param {String} event - The name of event
   * @param {Function} fn - The event listener
   * @param {*} [context=this] - The context to invoke the listener with.
   *
   * @returns {Function} - Unsubscribe method for subscribed function.
   */
  on(event, fn, context) {
    super.on(event, fn, context);
    return () => this.off(event, fn, context);
  }

  /**
   * Override for `EventEmitter3.once` method (one-time listener for a given event)
   *
   * @param {String} event - The name of event
   * @param {Function} fn - The event listener
   * @param {*} [context=this] - The context to invoke the listener with.
   *
   * @returns {Function} - Unsubscribe method for subscribed function.
   */
  once(event, fn, context) {
    super.once(event, fn, context);
    return () => this.off(event, fn, context);
  }

  updateSettings(options) {
    if ("pendingTime" in options) {
      this._pendingTime = lodash.isString(options.pendingTime) ? parseDuration(options.pendingTime) : options.pendingTime;
      this._pendingTime = lodash.clamp(this._pendingTime, minPendingTime, Infinity);
    }

    if ("disabled" in options) {
      this._disalbed = options.disabled;

      if (this._disalbed) {
        this._setStatus("running");
        this._stopTimeout();
      }
    }
  }

  destroy() {
    this.removeAllListeners();
    this._stopTimeout();
    window.removeEventListener("mousedown", this._onAction);
    window.removeEventListener("touchstart", this._onAction);
    window.removeEventListener("keydown", this._onAction);
  }

  _setStatus(status) {
    if (this.status !== status) {
      this.status = status;
      this.emit(status);
    }
  }

  _onAction() {
    if (this._disalbed)
      return;

    this._setStatus("running");
    this._startTimeout();
  }

  _startTimeout() {
    if (this._disalbed)
      return;

    this._stopTimeout();
    this._timeout = d3.timeout(() => {
      this._timeout = null;
      this._setStatus("pending");
    }, this._pendingTime);
  }

  _stopTimeout() {
    if (this._timeout) {
      this._timeout.stop();
      this._timeout = null;
    }
  }
}
