import lodash from "lodash";

const mouseButtonsMapping = {
  left: 0,
  middle: 1,
  right: 2
};

export default {
  created() {
    this._dragStart = this._dragStart.bind(this);
    this._dragMove = lodash.throttle(this._dragMove.bind(this), 16);
    this._dragEnd = this._dragEnd.bind(this);
    this.dragThreshold = 3;
    this.dragDirection = "any";
  },
  methods: {
    _getPointerPosition(event) {
      return {
        clientX: event.clientX || lodash.get(event, "touches[0].clientX"),
        clientY: event.clientY || lodash.get(event, "touches[0].clientY")
      };
    },
    _dragStart(event) {
      if (event.button !== mouseButtonsMapping.left && event.type !== "touchstart")
        return;

      const pointerPosition = this._getPointerPosition(event);
      this.startClientX = pointerPosition.clientX;
      this.startClientY = pointerPosition.clientY;
      this.startEvent = event;

      window.addEventListener("mousemove", this._dragMove);
      window.addEventListener("mouseup", this._dragEnd);

      window.addEventListener("touchmove", this._dragMove);
      window.addEventListener("touchend", this._dragEnd);

      this.finished = false;
    },
    _dragMove(event) {
      if (this.finished)
        return;

      const pointerPosition = this._getPointerPosition(event);
      const delta = {
        x: pointerPosition.clientX - this.startClientX,
        y: pointerPosition.clientY - this.startClientY
      };

      if (!this.started) {
        const verticalThresholdPassed = Math.abs(delta.y) >= this.dragThreshold;
        const horizontalThresholdPassed = Math.abs(delta.x) >= this.dragThreshold;
        const thresholdPassed =
          this.dragDirection === "vertical" && verticalThresholdPassed
          || this.dragDirection === "horizontal" && horizontalThresholdPassed
          || this.dragDirection === "any" && (verticalThresholdPassed || horizontalThresholdPassed);

        if (thresholdPassed) {
          this.$emit("dnd-start", this.startEvent);
          this.startEvent = null;
          this.started = true;
        }
      }

      if (this.started) {
        const appScale = this.$easyscreenCanvasOrientation.scale;

        Object.assign(event, {
          deltaX: delta.x / appScale,
          deltaY: delta.y / appScale
        });

        this.$emit("dnd-move", event);
      }
    },
    _dragEnd(event) {
      this.finished = true;
      if (this.started) {
        this.$emit("dnd-end", event);
      }

      this.started = false;
      this.startEvent = null;

      window.removeEventListener("mousemove", this._dragMove);
      window.removeEventListener("mouseup", this._dragEnd);

      window.removeEventListener("touchmove", this._dragMove);
      window.removeEventListener("touchend", this._dragEnd);
    }
  }
};
