<template>
  <div class="language-select">
    <div
      class="language-select--selected"
      @click="_toggleOptions"
    >
      <div class="language-select--selected-value">{{ selectedValue }}</div>
      <svg class="language-select--background" height="85.458" width="76.246208">
        <path
          id="path3401"
          :d="[
            'M 74,37.5',
            'C 74,57.65839 57.65839,74.00001 37.5,74.00001',
            'L 11.46099,84.30355 17.74412,68.19654',
            'C 7.67007,61.6996 1,50.37875 1,37.5 1,17.34162 17.34161,1 37.5,1 57.65839,1 74,17.34162 74,37.5',
            'Z'
          ].join(' ')"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="scccsss"
          style="opacity:1;fill:none;stroke:#ffffff;stroke-width:3;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1"/>
      </svg>
    </div>

    <div :class="[
      'language-select--options',
      { 'language-select--options_visible': isVisible }
    ]">
      <div
        v-for="option in normalizedOptions"
        :key="option.value"
        :class="[
          'language-select--option',
          { 'language-select--option_active': selectedValue === option.value }
        ]"
        @click="$emit('input', option.value); $emit('change', option.value); _hideOptions();"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<style lang="less" src="./language-select.less"></style>
<script>
  import lodash from "lodash";
  import isElementOf from "../../../dashboard/lib/is-element-of.js";

  export default {
    name: "language-select",
    props: {
      value: String,
      placeholder: String,
      options: {
        type: Array,
        default: () => ([])
      }
    },
    computed: {
      normalizedOptions() {
        return this.options.map(option => {
          if (lodash.isObjectLike(option))
            return option;

          return { label: option, value: option };
        });
      },
      selected() {
        const selected = this.normalizedOptions.find(option => {
          if (lodash.isObjectLike(option))
            return option.value === this.value;

          return option === this.value;
        });

        return selected || this.normalizedOptions[0];
      },
      selectedValue() {
        if (this.selected === undefined)
          return this.placeholder || "";

        return this.selected.value;
      }
    },
    data() {
      return {
        isVisible: false
      };
    },
    methods: {
      _showOptions() {
        this.isVisible = true;
      },
      _hideOptions(event) {
        if (event && isElementOf(event.target, "language-select"))
          return;

        this.isVisible = false;
      },
      _toggleOptions() {
        this.isVisible = !this.isVisible;
      }
    },
    mounted() {
      window.addEventListener("mouseup", this._hideOptions);
    },
    beforeDestroy() {
      window.removeEventListener("mouseup", this._hideOptions);
    },
    screenPending() {
      this.isVisible = false;
    }
  };
</script>
