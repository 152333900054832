var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    'wayfinder-button',
    ("wayfinder-button_" + _vm.corners),
    _vm.color ? ("color-" + _vm.color) : '',
    (_vm.size + "-button"),
    _vm.modificator,
    { active: _vm.active }
  ],attrs:{"aria-label":_vm.ariaLabelValue,"tabindex":_vm.tabindexValue}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }