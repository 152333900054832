<template>
  <div
    :class="[
      'wayfinder-loader',
      `wayfinder-loader_${ size }`
    ]"
    ref="modal"
    @before-open="(event) => $emit('before-open', event)"
    @opened="(event) => $emit('opened', event)"
    @before-close="(event) => $emit('before-close', event)"
    @closed="(event) => $emit('closed', event)"
    :position="position"
  >
    <div class="wayfinder-loader--spinner">
      <i class="fa fa-spinner fa-spin"></i>
    </div>
  </div>
</template>

<style lang="less" src="./loader.less" />
<script>
  export default {
    name: "wayfinder-loader",
    props: {
      size: {
        type: String,
        default: "big",
        validator: _size => ["big", "medium", "small"].includes(_size)
      },
      position: String
    }
  };
</script>
